import { useState } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import Routing from "./routes";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import "./App.css";

const queryClient = new QueryClient();

function App() {
  const { i18n } = useTranslation();
  const [direction, setDirection] = useState(i18n.dir());
  document.body.dir = i18n.dir();
  const language = i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    setDirection(i18n.dir());
  };
  
  return (
    <div className="App">
      <ConfigProvider
        direction={direction}
        theme={{
          token: {
            colorPrimary: "#f07d00",
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Routing language={language} changeLanguage={changeLanguage} />
        </QueryClientProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
