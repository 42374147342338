import React from "react";
import { AnimatePresence, m } from "framer-motion";
import Header from "../../components/Header";
import { container, item } from "../../utilities";
import "./managedocument.css";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../components/BreadCrumb";
import UploadDeleteFile from "../../components/UploadDeleteFile";
import { useLocation } from "react-router-dom";

const ManageDocument = ({ language, changeLanguage }) => {
    const location = useLocation();
    const title = location.state?.title;
    const description = location.state?.description;
    const validFileExtension = location.state?.validFileExtension;
    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem("user"));
    const fetchUrl = 'api/attachment?name=' + user.userId + title;
    const fileTitle = user.userId + title;
    const uploadUrl = '/api/attachment/create';
    const deleteUrl = 'api/attachment/';
    const fileAccessUrl = 'https://cvparse.fra1.cdn.digitaloceanspaces.com/';
    const dataObj = {
        isUserSpecific: 0,
        isRoleSpecific: 1,
        isHead: 1,
    };
    const navigation = [
        { id: 0, name: t("dashboard"), url: "/dashboard" },
        {
            id: 1,
            name: t(title),
            active: true,
        },
    ];

    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
        >
            <Header
                home={"/dashboard"}
                logOut={"/"}
                language={language}
                changeLanguage={changeLanguage}
            />
            <m.div
                className="accounting-contacts"
                variants={container}
                initial="hidden"
                animate="show"
            >
                <m.div className="title-text primary-color" variants={item}>
                    {t(description)}
                </m.div>
                <m.div className="accounting-filter-nav-header-without" variants={item}>
                    <BreadCrumb items={navigation} />
                </m.div>
                <AnimatePresence>

                </AnimatePresence>
                <m.div variants={item}>
                    <UploadDeleteFile
                        title={title}
                        fileTitle={fileTitle}
                        fetchUrl={fetchUrl}
                        uploadUrl={uploadUrl}
                        deleteUrl={deleteUrl}
                        fileAccessUrl={fileAccessUrl}
                        validFileExtension={validFileExtension}
                        dataObj={dataObj}
                    />
                </m.div>
            </m.div>
        </m.div >
    );
};

export default ManageDocument;
