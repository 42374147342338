import React, { lazy, Suspense } from "react";
import { LazyMotion, domAnimation, AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "../components/Loader";
import ScrollToTop from "./ScrollToTop";
import ManageDocument from "../pages/ManageDocument";

// Accounting
const Login = lazy(() => import("../pages/Login"));
const Dashboard = lazy(() => import("../pages/DashBoard"));
const Customer = lazy(() => import("../pages/Customer"));
const CustomerProfile = lazy(() => import("../pages/Customer/customerProfile"));
const ClientAttachments = lazy(() => import("../pages/ClientAttachments"));
const PreSelectionMeeting = lazy(() => import("../pages/PreSelectionMeeting"));
const MeetingDetails = lazy(() => import("../pages/MeetingDetails"));
const MeetingDetailsCreate = lazy(() =>
  import("../pages/MeetingCreation/MeetingDetails")
);
const AttendeesCreate = lazy(() =>
  import("../pages/MeetingCreation/Attendees")
);
const MeetingAgendaCreate = lazy(() =>
  import("../pages/MeetingCreation/MeetingAgenda")
);
const MomCreate = lazy(() =>
  import("../pages/MeetingCreation/MinutesOfMeeting")
);
const AttachmentsCreate = lazy(() =>
  import("../pages/MeetingCreation/Attachments")
);
const MeetingProfile = lazy(() =>
  import("../pages/MeetingDetails/meetingProfile")
);
const MeetingAgenda = lazy(() => import("../pages/MeetingAgenda"));
const MinutesOfMeeting = lazy(() => import("../pages/MinutesOfMeeting"));
const Preview = lazy(() =>
  import("../pages/MeetingCreation/Attachments/preview")
);
const ManageAccess = lazy(() => import("../pages/ManageAccess"));
const UserManage = lazy(() => import("../pages/UserManage"));
const CompanyManage = lazy(() => import("../pages/ManageCompany"));
const CallManage = lazy(() => import("../pages/ManageCall"));
const AssignManage = lazy(() => import("../pages/ManageAssign"));

// Misc
const PageNotFound = lazy(() => import("../components/NoPageFound"));
const NotAuthorize = lazy(() => import("../components/NotAuthorize"));

const Routing = ({ language, changeLanguage }) => {
  return (
    <div>
      <Suspense fallback={<Loader minHeight={"80vh"} />}>
        <Router>
          <AnimatePresence>
            <LazyMotion features={domAnimation}>
              <ScrollToTop />
              <Routes>
                {/* Accounting - Accountant & AH */}
                <Route
                  path="/"
                  element={
                    <Login
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/dashboard"
                  element={
                    <Dashboard
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/customer"
                  element={
                    <Customer
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/attachment"
                  element={
                    <ClientAttachments
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/preSelectionMeeting"
                  element={
                    <PreSelectionMeeting
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingDetails"
                  element={
                    <MeetingDetails
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingAgenda"
                  element={
                    <MeetingAgenda
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/minutesOfMeeting"
                  element={
                    <MinutesOfMeeting
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingDetails/:type"
                  element={
                    <MeetingDetailsCreate
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingDetails/:type/:id"
                  element={
                    <MeetingDetailsCreate
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingDetails/attendees/:id"
                  element={
                    <AttendeesCreate
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingDetails/meetingAgenda/:id"
                  element={
                    <MeetingAgendaCreate
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingDetails/minutesOfMeeting/:id"
                  element={
                    <MomCreate
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingDetails/attachments/:id"
                  element={
                    <AttachmentsCreate
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingProfile/:id"
                  element={
                    <MeetingProfile
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/meetingDetails/preview/:id"
                  element={
                    <Preview
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/customer/profile/:id"
                  element={
                    <CustomerProfile
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/manageAccess"
                  element={
                    <ManageAccess
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/manageUser"
                  element={
                    <UserManage
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/manageCompany"
                  element={
                    <CompanyManage
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/manageCall"
                  element={
                    <CallManage
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/manageAssign"
                  element={
                    <AssignManage
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/documentManage"
                  element={
                    <ManageDocument
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                {/* Misc */}
                <Route
                  path="/notAuthorized"
                  element={
                    <NotAuthorize
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="*"
                  element={
                    <PageNotFound
                      language={language}
                      changeLanguage={changeLanguage}
                    />
                  }
                ></Route>
              </Routes>
            </LazyMotion>
          </AnimatePresence>
        </Router>
      </Suspense>
    </div>
  );
};

export default Routing;
