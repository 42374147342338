import React, { useCallback, useEffect, useRef, useState } from "react";
import { m } from "framer-motion";
import Cookies from "universal-cookie";
import { container } from "../../utilities";
import "./uploadDelete.css";
import { InboxOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Card, message, Upload, Popconfirm, Tooltip, Typography, Spin, Alert } from 'antd';
import axios from 'axios';
const { Dragger } = Upload;
const { Text } = Typography;

const UploadDeleteFile = (props) => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const userData = JSON.parse(localStorage.getItem('user'));
    const isHead = userData?.isHead || false;
    const [isLoading, setLoading] = useState(true);
    const [getFileUrl, setFileUrl] = useState(null);
    const [fileID, setFileId] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileExist, setFileExist] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const downloadRef = useRef(null);

    const title = props.title || '';
    const fileTitle = props.fileTitle || '';
    const uploadUrl = props.uploadUrl || '';
    const validFileExtension = props.validFileExtension || '';
    const dataObj = props.dataObj || '';
    const fetchUrl = props.fetchUrl || '';
    const deleteUrl = props.deleteUrl || '';
    const fileAccessUrl = props.fileAccessUrl || '';

    /* Check File Type */
    const getFileType = (file) => {
        const fileTypeMap = {
            pdf: 1,
            xls: 2,
            xlsx: 2,
            jpg: 3,
            jpeg: 3,
            png: 3,
        };
        const extension = file.name.split('.').pop().toLowerCase();
        return fileTypeMap[extension] || 0;
    };

    /* Handle Upload File */
    const uploadFile = {
        maxCount: 1,
        action: uploadUrl,
        headers: {
            authorization: 'Bearer ' + token,
        },
        data(file) {
            const fileType = getFileType(file);
            return {
                ...dataObj,
                fileType: fileType,
                name: fileTitle,
            };
        },
        beforeUpload(file) {
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
            if (!validFileExtension.includes(fileExtension)) {
                message.error(`${file.name} is not a valid file format`);
                return false;
            }
            return true;
        },
        onChange(info) {
            const { status, response } = info.file;
            const fileName = info.file.name;
            if (status === 'done' && response.message) {
                message.success(`${fileName} file uploaded successfully.`);
                setLoading(true);
                fetchData();
            } else if (status === 'error') {
                message.error(`${fileName} file upload failed.`);
            }
        },
    };

    /* Fetch File Data*/
    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get(fetchUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data.data.length > 0) {
                setFileUrl(response.data.data[0].url);
                setFileId(response.data.data[0].id);
                setFileName(response.data.data[0].name);
                setFileExist(true)
            } else {
                setFileExist(false)
            }
            setLoading(false);
        } catch (error) {
            // message.warning('File Not Found.');
            setLoading(false);
        }
    }, [fetchUrl, token]);

    useEffect(() => {
        fetchData();
        return () => {
            setLoading(true);
            setFileUrl(null);
            setFileId(null);
            setFileName('');
            setFileExist(false)
        }
    }, [fetchData]);

    /* Show Confirmation Box to Delete File */
    const showPopconfirm = () => {
        setOpenConfirm(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        handleDeleteApi()
        setTimeout(() => {
            setOpenConfirm(false);
            setConfirmLoading(false);
        }, 3000);
    };
    const handleCancel = () => {
        setOpenConfirm(false);
    };

    /* Delete File */
    const handleDeleteApi = async () => {
        try {
            const res = await axios.delete(deleteUrl + fileID, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res && res.data.message) {
                setFileId(null);
                setFileUrl(null);
                setFileName('');
                message.success('File deleted successfully.');
                fetchData();
                setLoading(true);
            }
        } catch (error) {
            message.warning('Something Went Wrong! Please try again.');
        }
    }

    /* Download File */
    const downloadFile = (e) => {
        e.preventDefault();
        if (downloadRef.current) {
            downloadRef.current.click();
        }
    };

    function extractFileName(fileName) {
        const regex = /^\d+\s*(.*)$/;
        const match = fileName.match(regex);
        const displayName = match ? match[1] : fileName;
        return displayName;
    }

    return (
        <m.div>
            {/* Show Spinner */}
            {isLoading && (
                <m.div className="spin-display">
                    <Spin size="large" />
                </m.div>
            )}

            {/* Upload File Section */}
            {!isLoading && getFileUrl === null && isHead && (
                <m.div>
                    <Card
                        title=""
                        className="document-wrapper file-title"
                        bordered={false}
                    >
                        <Text type="warning" className="file-title">{title}</Text>
                        <div style={{ marginTop: '10px' }}>
                            <Dragger {...uploadFile}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single upload.
                                </p>
                            </Dragger>
                        </div>
                    </Card>
                </m.div>
            )}

            {/* If File Not Exist & Show only if User Not Head  */}
            {!isLoading && !fileExist && !isHead && (
                <m.div>
                    <Card
                        title=""
                        className="document-wrapper file-title"
                        bordered={false}
                    >
                        <Alert
                            // message=''
                            description={title + ' is not uploaded'}
                            type="error"
                            showIcon
                        />

                    </Card>
                </m.div>
            )}

            {/* Download & Delete File Section  */}
            {!isLoading && getFileUrl !== null && (
                <m.div
                    className="ds-card-main"
                    variants={container}
                    initial="hidden"
                    animate="show"
                >
                    <m.div className="card-main__each">
                        <h3 className="title">{extractFileName(fileName)}</h3>
                        <div className="icon-display">
                            <a
                                href={fileAccessUrl + getFileUrl}
                                download={fileName}
                                ref={downloadRef}
                                style={{ display: 'none' }}
                            >
                                Download
                            </a>
                            <div className="card-icon">
                                <Tooltip title="Download File" placement="bottom" color="orange">
                                    <DownloadOutlined className="icon-color" onClick={(e) => downloadFile(e)} />
                                </Tooltip>
                            </div>

                            {/* Show Delete Button if isHead Login */}
                            {isHead && (
                                <div className="card-icon">
                                    <Popconfirm
                                        title={extractFileName(fileName)}
                                        description="Are you sure want to delete it?"
                                        open={openConfirm}
                                        onConfirm={handleOk}
                                        okButtonProps={{
                                            loading: confirmLoading,
                                        }}
                                        onCancel={handleCancel}
                                    >
                                        <Tooltip title="Delete File" placement="bottom" color="orange">
                                            <DeleteOutlined className="icon-color" onClick={showPopconfirm} />
                                        </Tooltip>
                                    </Popconfirm>
                                </div>
                            )}
                        </div>
                    </m.div>
                </m.div>
            )}
        </m.div>

    );
};

export default UploadDeleteFile;
